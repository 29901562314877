import { useUserMetadata } from "admin-portal-shared-services";

interface UserScopesReturn {
  isLoading: boolean;
  userScopes: string[] | undefined;
}

export default function useUserScopes(): UserScopesReturn {
  const userMetadata = useUserMetadata();

  return {
    isLoading: userMetadata.isLoading,
    userScopes: userMetadata.data?.authorization?.scopes,
  };
}
