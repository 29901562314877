export const BASE_URL = "/analytics-admin";

export const MFE_ROUTES = {
  HOME: `${BASE_URL}`,
  CREATE_WORKSPACE: `${BASE_URL}/workspace/create`,
  WORKSPACE: `${BASE_URL}/workspace/:workspaceId`,
  THEME_DETAILS: `${BASE_URL}/workspace/:workspaceId/:themeId`,
};

export const GRAY_COLOR = "rgba(20, 20, 20, 0.56)";

export const SCOPES = {
  FEATURE_ACTIVATION_READ: "PartnerDashboard.FeatureActivation.Read",
  FEATURE_ACTIVATION_WRITE: "PartnerDashboard.FeatureActivation.Write",
};

export const REQUIRED_SCOPES = {
  FEATURE_ACTIVATION_READ: [SCOPES.FEATURE_ACTIVATION_READ],
  FEATURE_ACTIVATION_WRITE: [SCOPES.FEATURE_ACTIVATION_WRITE],
};

export const TRACKINGID_REGEX = /^[A-Z0-9]+(_[A-Z0-9]+)*$/;

export const EU_CLUSTER_COUNTRIES = ["PH", "ID"];
export const EU_CLUSTER = "Global_EU";
export const BFF_URL = "/partner-analytics-business-service";

export const ERROR_COLOR = "$semanticErrorText";

export enum ENDPOINTS {
  WORKSPACES = "/workspaces",
  GET_REPORT_GROUP = "GET_REPORT_GROUP",
  ADD_REPORT_GROUP = "ADD_REPORT_GROUP",
  UPDATE_REPORT_GROUP_BY_ID = "UPDATE_REPORT_GROUP_BY_ID",
  GET_REPORT_GROUP_BY_ID = "GET_REPORT_GROUP_BY_ID",
}
