import { useMemo } from 'react';

import { User } from '@hexa-ui/icons';
import { SidebarItem } from 'admin-portal-shared-services';

import { MFE_ROUTES } from '@/Constants';

/* istanbul ignore next */
const UserIcon = (): React.ReactElement => <User />;

export const useSidebarItems = (): SidebarItem[] => {
  return useMemo(() => {
    const items: SidebarItem[] = [];

    items.push({
      id: 'workspaces',
      icon: UserIcon,
      path: MFE_ROUTES.HOME,
      title: 'Workspaces',
    });

    return items;
  }, []);
};
